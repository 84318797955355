import axios from 'axios';

let API_BASE_URL = process.env.REACT_APP_API_URL;

// if (API_BASE_URL) {
//     API_BASE_URL = API_BASE_URL.slice(1, -2);
// }

export const loginUser = async (email, password) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/auth/login`, { email, password });
        return response.data;
    } catch (error) {
        if (error.response?.status === 401) {
            throw new Error('Invalid email or password');
        }
        const errorMessage = error.response?.data?.message || 'An error occurred during login';
        throw new Error(errorMessage);
    }
};

export default loginUser;
