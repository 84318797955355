import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  PieChart, 
  Database, 
  Container, 
  X, 
  ChevronDown,
  TrendingUp,
  DollarSign,
  Package,
  Tag,
  LogOut,
  Menu,
  User
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import './Dashboard.css';
import UnderConstruction from './UnderConstruction';
import SettingsPopup from './settings';
import FullScreenPowerBIEmbed from './powerbi';
import NotificationBar from './NotificationBar';
import StatusDialog from './Dialog';
import Summary from './SummaryPage';
import Potable from './PoTable';

const Dashboard = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [user, setUser] = useState({
    name: '',
    imageUrl: '',
    gender: 'male',
    role: '',
    department: '',
    lastActive: new Date(),
  });
  const [activeContent, setActiveContent] = useState('DSA');
  const [expandedMenu, setExpandedMenu] = useState(null);
  
  const [bellNotifications, setBellNotifications] = useState([
    { id: 1, message: 'New order received', read: false, type: 'success', time: '5 min ago' },
    { id: 2, message: 'Payment processed', read: false, type: 'info', time: '30 min ago' },
    { id: 3, message: 'Stock alert', read: false, type: 'warning', time: '1 hour ago' },
    { id: 4, message: 'New feature available', read: false, type: 'info', time: '2 hours ago' },
    { id: 5, message: 'System maintenance tonight', read: false, type: 'warning', time: '4 hours ago' },
  ]);
  const [showMailNotifications, setShowMailNotifications] = useState(false);
  const [showBellNotifications, setShowBellNotifications] = useState(false);
  const [logoutStatus, setLogoutStatus] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [currentPowerBIPage, setCurrentPowerBIPage] = useState(null);
  const [dialogStatus, setDialogStatus] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const mailNotificationRef = useRef(null);
  const bellNotificationRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (expandedMenu === 'Ecom Dashboard' && activeContent === 'Home') {
      setActiveContent('Sales');
    }
  }, [expandedMenu, activeContent]);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      setUser({
        name: `${userData.first_name} ${userData.last_name}`,
        imageUrl: userData.profile_picture || `https://ui-avatars.com/api/?name=${userData.first_name}+${userData.last_name}&background=4f46e5&color=fff`,
        gender: userData.gender || 'male',
        role: userData.role || 'Team Member',
        department: userData.department || 'General',
        lastActive: new Date(),
      });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mailNotificationRef.current && !mailNotificationRef.current.contains(event.target)) {
        setShowMailNotifications(false);
      }
      if (bellNotificationRef.current && !bellNotificationRef.current.contains(event.target)) {
        setShowBellNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
    }, 2000);
    return () => clearTimeout(timer);
  }, [activeContent]);

  const [menuItems, setMenuItems] = useState([
    { 
      icon: PieChart, 
      text: 'DSA', 
      hasSubItems: true,
      subItems: [],
      isLoading: true
    },
    { 
      icon: Database,
      text: 'Ecom Dashboard',
      hasSubItems: true,
      subItems: [
        { icon: TrendingUp, text: 'Sales' },
        { icon: DollarSign, text: 'Revenue' },
        { icon: Package, text: 'Products' },
        { icon: Tag, text: 'Marketing Campaigns' }
      ]
    },
    {
      icon: Package,
      text: 'PO Dashboard',
      hasSubItems: true,
      subItems: [
        { icon: TrendingUp, text: 'Summary' },
        { icon: Database, text: 'PO Table' }
      ]
    },
    { icon: User, text: 'Admin' },
  ]);

  const handlePowerBIPagesLoaded = useCallback((pages, activePage = null) => {
    
    
    if (!pages || pages.length === 0) {
      console.warn('No PowerBI pages received');
      return;
    }

    if (!currentPowerBIPage) {
      setCurrentPowerBIPage(activePage || {
        id: pages[0].name,
        displayName: pages[0].displayName
      });
    }

    setMenuItems(prevItems => {
      return prevItems.map(item => {
        if (item.text === 'DSA') {
          return {
            ...item,
            hasSubItems: true,
            isLoading: false,
            subItems: pages.map(page => ({
              icon: TrendingUp,
              text: page.displayName,
              name: page.name,
              displayName: page.displayName
            }))
          };
        }
        return item;
      });
    });
  }, [currentPowerBIPage]);

  const renderContent = () => {
    switch (activeContent.toLowerCase()) {
      case 'dsa':
        return (
          <div className="dashboard-container">
            <div className="powerbi-wrapper glass-effect">
              <FullScreenPowerBIEmbed 
                reportType="dsa" 
                onPagesLoaded={handlePowerBIPagesLoaded}
                currentPage={currentPowerBIPage}
                key={currentPowerBIPage}
              />
            </div>
          </div>
        );
      case 'summary':
        return <Summary />;
      case 'po table':
        return <Potable />;
      case 'ecom dashboard':
      case 'sales':
      case 'revenue':
      case 'products':
      case 'marketing campaigns':
        return <UnderConstruction />;
      case 'admin':
        return <AdminDashboard />;
      default:
        return <UnderConstruction />;
    }
  };

  const handleMenuItemClick = (menuText) => {
    if (menuText === 'DSA' || menuText === 'Ecom Dashboard' || menuText === 'PO Dashboard') {
      setExpandedMenu(prevMenu => prevMenu === menuText ? null : menuText);
      if (expandedMenu !== menuText) {
        // Set initial content for each menu
        if (menuText === 'DSA') {
          setActiveContent('DSA');
        } else if (menuText === 'PO Dashboard') {
          setActiveContent('Summary');
        } else {
          setActiveContent('Sales');
        }
      }
    } else {
      setActiveContent(menuText);
      setExpandedMenu(null);
    }
  };

  const handleSubItemClick = (subItemText, platform = null, event) => {
    if (event) {
      event.stopPropagation();
    }
    
    // Check if it's an Ecom Dashboard sub-item
    const ecomSubItems = ['Sales', 'Revenue', 'Products', 'Marketing Campaigns'];
    if (ecomSubItems.includes(subItemText.text || subItemText)) {
      setActiveContent(subItemText.text || subItemText);
      return;
    }

    // Check if it's a PO Dashboard sub-item
    const poSubItems = ['Summary', 'PO Table'];
    if (poSubItems.includes(subItemText.text || subItemText)) {
      setActiveContent(subItemText.text || subItemText);
      return;
    }
    
    // Only process PowerBI pages for DSA items
    if (expandedMenu === 'DSA') {
      let pageId;
      if (typeof subItemText === 'object') {
        pageId = subItemText.id || subItemText.name || subItemText.text;
      } else {
        pageId = subItemText;
      }
      
      if (!pageId) {
        console.warn('No page ID found in:', subItemText);
        return;
      }
      
      setActiveContent('DSA');
      setCurrentPowerBIPage(pageId);
    }
  };

  const handleLogout = () => {
    setLogoutStatus('loading');
    setDialogStatus('loading');
    
    setTimeout(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setLogoutStatus('success');
      setDialogStatus('success');
      
      setTimeout(() => {
        navigate('/login');
      }, 1500);
    }, 1500);
  };

  const handleSuccessDialogClose = () => {
    setDialogStatus(null);
    navigate('/login');
  };

  const handleErrorDialogClose = () => {
    setDialogStatus(null);
  };

  const toggleNotifications = (type) => {
    if (type === 'mail') {
      setShowMailNotifications(prev => !prev);
      setShowBellNotifications(false);
    } else {
      setShowBellNotifications(prev => !prev);
      setShowMailNotifications(false);
    }
  };

  const clearAllNotifications = (type) => {
    if (type === 'mail') {
      return;
    } else {
      setBellNotifications([]);
    }
  };

  const removeNotification = (type, id) => {
    if (type !== 'mail') {
      setBellNotifications(prevNotifications => 
        prevNotifications.filter(notif => notif.id !== id)
      );
    }
  };

  const renderSubmenuItem = (subItem) => {
    return (
      <div
        key={subItem.id || subItem.text}
        className={`submenu-item ${currentPowerBIPage === (subItem.id || subItem.text) ? 'active' : ''}`}
        onClick={(event) => handleSubItemClick(subItem, null, event)}
      >
        <div className="submenu-content">
          <span className="submenu-title">
            {subItem.displayName || subItem.text}
          </span>
          {subItem.description && (
            <span className="submenu-description">{subItem.description}</span>
          )}
        </div>
      </div>
    );
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="dashboard-layout">
      {/* Sidebar */}
      <aside className={`sidebar ${isSidebarOpen ? 'active' : ''}`}>
        <div className="sidebar-header">
          <div className="brand-container">
            <div className="brand-section">
              <div className="brand-logo">
                <img src="https://i.ibb.co/vDqg4jC/no-Bg-Color.png" alt="Logo" className="company-logo" />
              </div>
              
              {/* <div className="brand">
                <img src="https://i.ibb.co/sgh73w5/KRBL-ltd.png" alt="brandlogo" className="brandlogo-logo" />
                </div> */}
              {/* <div className="brand-info">
                <span className="powered-text">Product</span>
                <div className="orange-section">
                  <img src="https://i.ibb.co/wQMtL7D/orange.png" alt="Orange icon" className="orange-icon" />
                  <span className="orange-text">orange</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        
        <nav className="sidebar-nav">
          {menuItems.map((item, index) => (
            <div key={index} className="nav-group">
              <div 
                className={`nav-item ${activeContent === item.text ? 'active' : ''}`}
                onClick={() => handleMenuItemClick(item.text)}
              >
                <item.icon className="nav-icon" />
                <span>{item.text}</span>
                {item.hasSubItems && (
                  <ChevronDown 
                    className={`arrow ${expandedMenu === item.text ? 'expanded' : ''}`} 
                  />
                )}
              </div>
              
              {item.hasSubItems && expandedMenu === item.text && (
                <div className="submenu">
                  {item.isLoading ? (
                    <div className="submenu-loading">Loading pages...</div>
                  ) : item.subItems && item.subItems.length > 0 ? (
                    item.subItems.map((subItem, subIndex) => (
                      <React.Fragment key={subIndex}>
                        {renderSubmenuItem(subItem)}
                      </React.Fragment>
                    ))
                  ) : (
                    <div className="submenu-empty">No pages available</div>
                  )}
                </div>
              )}
            </div>
          ))}
        </nav>

        <div className="sidebar-footer">
          <button 
            className="logout-btn" 
            onClick={handleLogout}
            disabled={logoutStatus === 'loading'}
          >
            <LogOut className="logout-icon" />
            <span>Logout</span>
          </button>
        </div>
      </aside>

      {isMobile && (
        <div 
          className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`}
          onClick={closeSidebar}
        />
      )}

      {isMobile && (
        <button 
          className="mobile-nav-toggle"
          onClick={toggleMobileMenu}
          aria-label="Toggle navigation"
        >
          {isSidebarOpen ? (
            <X size={24} className="menu-icon active" />
          ) : (
            <Menu size={24} className="menu-icon" />
          )}
        </button>
      )}

      {/* Main Content */}
      <main className="main-container">
        <header className="top-header glass-effect">
          <div className="header-left">
            <button className="menu-toggle glass-effect" onClick={() => setIsMobile(!isMobile)}>
              <Container />
            </button>
            <h1 className="page-title gradient-text">{activeContent}</h1>
          </div>

          <NotificationBar 
            notifications={bellNotifications}
            showNotifications={showBellNotifications || showMailNotifications}
            toggleNotifications={toggleNotifications}
            clearAllNotifications={clearAllNotifications}
            removeNotification={removeNotification}
            notificationRef={bellNotificationRef}
            setIsSettingsOpen={setIsSettingsOpen}
            user={user}
          />
        </header>

        <div className="content">
          {renderContent()}
        </div>
      </main>

      {isSettingsOpen && (
        <div className="settings-overlay" onClick={() => setIsSettingsOpen(false)}>
          <div className="settings-modal" onClick={e => e.stopPropagation()}>
            <SettingsPopup
              isOpen={isSettingsOpen}
              onClose={() => setIsSettingsOpen(false)}
              user={user}
            />
          </div>
        </div>
      )}

      {dialogStatus && (
        <StatusDialog
          status={dialogStatus}
          onSuccess={handleSuccessDialogClose}
          onClose={handleErrorDialogClose}
          errorMessage=""
          action="logout"
        />
      )}
    </div>
  );
};

export default Dashboard;