import React, { useState, useEffect, useCallback } from 'react';
import { fetchDataFromAPI, fetchPodata } from '../routes/AppRoutes';
import * as XLSX from 'xlsx';
import { ChevronDown, ChevronRight, RotateCw, RefreshCw, Check, FileText, Download, Edit, Trash2, Search, X } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './PoTable.css';

const Modal = ({ show, onClose, children }) => {
  if (!show) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">Edit Item Details</h2>
          <button onClick={onClose} className="modal-close">
            <X size={24} />
          </button>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

const PoTable = ({ platform }) => {
  const [selectedPlatform, setSelectedPlatform] = useState(platform || "Amazon");

  const platforms = ["Amazon", "Flipkart", "Blinkit", "BigBasket", "Zepto"];

  const handlePlatformChange = (platform) => {
    setSelectedPlatform(platform);
    loadOrders(platform);
  };

  const [columns, setColumns] = useState([]);
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');
  const [poNumberSearch, setPoNumberSearch] = useState('');
  const [activeToggle, setActiveToggle] = useState(null);
  const [toppledData, setToppledData] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const renderStatus = (status) => {
    return <span className={`status-badge status-${status.toLowerCase()}`}>{status}</span>;
  };

  const applyFilters = useCallback((ordersToFilter) => {
    let filteredOrders = ordersToFilter;

    if (statusFilter) {
      filteredOrders = filteredOrders.filter(order => 
        order.STATUS && order.STATUS.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    if (poNumberSearch) {
      filteredOrders = filteredOrders.filter(order => 
        order.PoNumber && order.PoNumber.toLowerCase().includes(poNumberSearch.toLowerCase())
      );
    }

    if (startDate && endDate) {
      filteredOrders = filteredOrders.filter(order => {
        if (!order.OrderDate) return false;
        const orderDate = new Date(order.OrderDate);
        return orderDate >= startDate && orderDate <= endDate;
      });
    }

    setOrders(filteredOrders);
  }, [statusFilter, poNumberSearch, startDate, endDate]);

  const loadOrders = useCallback(async (platform) => {
    setIsLoading(true);
    try {
      const data = await fetchDataFromAPI(platform);
      if (Array.isArray(data) && data.length > 0) {
        const orderData = data[0];
        setColumns(Object.keys(orderData[0]));
        setAllOrders(orderData.map(order => ({ ...order, selected: false })));
        applyFilters(orderData);
      }
    } catch (error) {
      console.error('Error loading orders:', error);
    } finally {
      setIsLoading(false);
    }
  }, [applyFilters]);

  useEffect(() => {
    loadOrders(selectedPlatform);
  }, [loadOrders, selectedPlatform]);

  useEffect(() => {
    applyFilters(allOrders);
  }, [applyFilters, allOrders, statusFilter, poNumberSearch, startDate, endDate]);

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handlePoNumberSearchChange = (e) => {
    setPoNumberSearch(e.target.value);
  };

  const handleDateChange = (update) => {
    const [start, end] = update;
    setStartDate(start);
    setEndDate(end);
  };

  const toggleRow = async (index) => {
    if (activeToggle === index) {
      setActiveToggle(null);
    } else {
      setActiveToggle(index);
      const orderNumber = orders[index].PoNumber;
      try {
        const toppledData = await fetchPodata(orderNumber);
        setToppledData(prevState => ({
          ...prevState,
          [orderNumber]: toppledData
        }));
      } catch (error) {
        console.error('Error loading toppled data:', error);
      }
    }
  };

  const toggleSelectAll = () => {
    const newState = !selectAll;
    setOrders(prevOrders => prevOrders.map(order => ({ ...order, selected: newState })));
    setSelectAll(newState);
  };

  const handleCheckboxChange = (index) => {
    setOrders(prevOrders => {
      const updatedOrders = [...prevOrders];
      updatedOrders[index].selected = !updatedOrders[index].selected;
      return updatedOrders;
    });
    setSelectAll(orders.every(order => order.selected));
  };

  const exportToExcel = () => {
    const selectedOrders = orders.filter(order => order.selected);
    if (selectedOrders.length === 0) {
      alert('Please select at least one order to export.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(selectedOrders);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveExcelFile(excelBuffer, 'exported_data.xlsx');
  };

  const saveExcelFile = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      console.log('Parsed Excel data:', parsedData);
      // Process the parsed data as needed
    };
    reader.readAsArrayBuffer(file);
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toLocaleDateString();
  };

  const renderCellContent = (value) => {
    if (value === null || value === undefined || value === '') {
      return '';
    } else if (typeof value === 'string' && value.startsWith('2024-')) {
      return formatDate(value);
    } else if (typeof value === 'object') {
      return JSON.stringify(value);
    } else {
      return value;
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditingItem(null);
  };

  const handleSaveEdit = () => {
    console.log('Saving edited item:', editingItem);
    setToppledData(prevState => ({
      ...prevState,
      [editingItem.PoNumber]: prevState[editingItem.PoNumber].map(item =>
        item.ItemId === editingItem.ItemId ? editingItem : item
      )
    }));
    handleCloseEditModal();
  };  

  const handleEditItemChange = (field, value) => {
    if (field === 'Price' || field === 'Quantity') {
      setEditingItem(prevItem => ({
        ...prevItem,
        [field]: parseFloat(value)
      }));
    }
  };

  const handleAddRemark = (newRemark) => {
    setEditingItem(prevItem => ({
      ...prevItem,
      Remarks: prevItem.Remarks ? `${prevItem.Remarks}\n${newRemark}` : newRemark
    }));
  };

  const handleDeleteItem = (orderNumber, itemIndex) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      setToppledData(prevState => ({
        ...prevState,
        [orderNumber]: prevState[orderNumber].filter((_, index) => index !== itemIndex)
      }));
    }
  };

  const handleDeleteClick = (orderNumber) => {
    if (window.confirm('Are you sure you want to delete this order?')) {
      console.log('Deleting order:', orderNumber);
      setOrders(prevOrders => prevOrders.filter(order => order.PoNumber !== orderNumber));
    }
  };

  const renderEditModalContent = () => {
    if (!editingItem) return null;

    return (
      <div className="edit-modal-content">
        <table className="edit-modal-table">
          <tbody>
            {Object.entries(editingItem).map(([field, value]) => (
              <tr key={field}>
                <td className="field-name">{field}</td>
                <td className="field-value">
                  {field === 'Price' || field === 'Quantity' ? (
                    <input 
                      type="number" 
                      value={value} 
                      onChange={(e) => handleEditItemChange(field, e.target.value)}
                      className="edit-input"
                    />
                  ) : field === 'Remarks' ? (
                    <>
                      <div className="current-remarks">{value}</div>
                      <input 
                        type="text" 
                        placeholder="Add new remark"
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleAddRemark(e.target.value);
                            e.target.value = '';
                          }
                        }}
                        className="edit-input"
                      />
                    </>
                  ) : field === 'ItemId' ? (
                    <span className="item-id">{value}</span>
                  ) : (
                    <span>{value}</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="modal-actions">
          <button className="btn btn-secondary" onClick={handleCloseEditModal}>
            Cancel
          </button>
          <button className="btn btn-primary save-btn" onClick={handleSaveEdit}>
            Save Changes
          </button>
        </div>
      </div>
    );
  };

  const renderToppledData = (orderNumber) => {
    const data = toppledData[orderNumber];
    if (!data || data.length === 0) return null;

    const columnNames = Object.keys(data[0]);

    return (
      <div className="toppled-data">
        <h6 className="toppled-data-title">Order Details</h6>
        <div className="table-responsive">
          <table className="table table-sm toppled-table">
            <thead>
              <tr>
                {columnNames.map((columnName, index) => (
                  <th key={index}>{columnName}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {columnNames.map((columnName, cellIndex) => (
                    <td key={cellIndex}>{renderCellContent(item[columnName])}</td>
                  ))}
                  <td>
                    <button 
                      className="btn btn-sm btn-outline-primary me-2"
                      onClick={() => handleEditClick(orderNumber, rowIndex)}
                    >
                      <Edit size={14} />
                      <span>Edit</span>
                    </button>
                    <button 
                      className="btn btn-sm btn-outline-danger"
                      onClick={() => handleDeleteItem(orderNumber, rowIndex)}
                    >
                      <Trash2 size={14} />
                      <span>Delete</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const handleEditClick = (orderNumber, itemIndex) => {
    fetchPodata(orderNumber).then(data => {
      setEditingItem(data[itemIndex]);
      setShowEditModal(true);
    }).catch(error => {
      console.error('Error fetching order details:', error);
      alert('Failed to load item details. Please try again.');
    });
  };

  const renderPlatformTabs = () => (
    <div className="platform-tabs">
      {platforms.map((platform, index) => (
        <button
          key={platform}
          className={`platform-tab ${selectedPlatform === platform ? 'active' : ''} 
                     ${isLoading ? 'loading' : ''}`}
          onClick={() => handlePlatformChange(platform)}
        >
          <span className="platform-name">{platform}</span>
          {orders.length > 0 && (
            <span className="badge">
              {orders.length.toLocaleString()}
            </span>
          )}
        </button>
      ))}
    </div>
  );

  const LoadingOverlay = () => (
    isLoading && (
      <div className="loading-overlay">
        <div className="loading-spinner" />
      </div>
    )
  );

  return (
    <div className="po-table-container">
      {renderPlatformTabs()}
      <div className="filters-card">
        <div className="filters-content">
          <div className="filters-row">
            <div className="filter-item">
              <select 
                id="statusFilter"
                value={statusFilter} 
                onChange={handleStatusFilterChange}
                className="form-select"
              >
                <option value="">All Status</option>
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Shipped">Shipped</option>
                <option value="Delivered">Delivered</option>
                <option value="Canceled">Canceled</option>
                <option value="Unknown">Unknown</option>
              </select>
            </div>
            <div className="filter-item">
              <div className="input-group input-group-sm">
                <span className="input-group-text"><Search size={14} /></span>
                <input 
                  id="poSearch"
                  type="text" 
                  className="form-control form-control-sm" 
                  placeholder="Search PO Number..." 
                  value={poNumberSearch}
                  onChange={handlePoNumberSearchChange}
                />
              </div>
            </div>
            <div className="filter-item">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                placeholderText="Select date range"
                className="form-control form-control-sm"
              />
            </div>
          </div>
        </div>
        
        <div className="actions-row">
          <div className="action-item">
            <button className="btn btn-sm btn-primary" onClick={loadOrders} title="Load Orders">
              <RefreshCw size={14} className="me-1" />
              <span>Load</span>
            </button>
          </div>
          <div className="action-item">
            <button className="btn btn-sm btn-secondary" title="Hard Refresh" onClick={() => window.location.reload()}>
              <RotateCw size={14} className="me-1" />
              <span>Refresh</span>
            </button>
          </div>
          <div className="action-item">
            <button className="btn btn-sm btn-success" title="Approve Orders">
              <Check size={14} className="me-1" />
              <span>Approve</span>
            </button>
          </div>
          <div className="action-item">
            <button className="btn btn-sm btn-info" onClick={() => document.getElementById('fileInput').click()} title="Upload Excel">
              <FileText size={14} className="me-1" />
              <span>Upload</span>
            </button>
            <input 
              id="fileInput" 
              type="file" 
              accept=".xlsx,.xls" 
              onChange={handleFileUpload} 
              style={{ display: 'none' }} 
            />
          </div>
          <div className="action-item">
            <button className="btn btn-sm btn-dark" onClick={exportToExcel} title="Export to Excel">
              <Download size={14} className="me-1" />
              <span>Export</span>
            </button>
          </div>
        </div>
      </div>

      <div className="table-wrapper position-relative">
        <LoadingOverlay />
        <div className="table-scroll">
          <table className="table" role="table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectAll}
                    onChange={toggleSelectAll}
                  />
                </th>
                <th></th>
                {columns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <React.Fragment key={order.PoNumber || index}>
                  <tr className={activeToggle === index ? 'active-row' : ''}>
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={order.selected}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </td>
                    <td>
                      <button 
                        className="btn btn-sm btn-link p-0 toggle-btn"
                        onClick={() => toggleRow(index)}
                      >
                        {activeToggle === index ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
                      </button>
                    </td>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex}>
                        {column === 'Status' ? renderStatus(order[column]) : renderCellContent(order[column])}
                      </td>
                    ))}
                    <td>
                      <button 
                        className="btn btn-sm btn-outline-danger delete-btn"
                        onClick={() => handleDeleteClick(order.PoNumber)}
                      >
                        <Trash2 size={14} />
                        <span>Delete</span>
                      </button>
                    </td>
                  </tr>
                  {activeToggle === index && (
                    <tr className="details-row">
                      <td colSpan={columns.length + 3}>
                        {renderToppledData(order.PoNumber)}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showEditModal} onClose={handleCloseEditModal}>
        {renderEditModalContent()}
      </Modal>
    </div>
  );
};

export default PoTable;
              