import React, { useState, useEffect, useCallback } from 'react';
import ReactApexChart from 'react-apexcharts';
import './SummaryPage.css';
import { fetchDashboardData } from '../routes/AppRoutes';

const SummaryPage = () => {
  const [chartOptions, setChartOptions] = useState({
    platformOptions: null,
    statusOptions: null,
    salesOptions: null
  });
  const [dashboardData, setDashboardData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [filters, setFilters] = useState({
    Distribution: 'All',
    platform: 'All',
    fromDate: '',
    toDate: '',
    status: 'All'
  });

  const applyFilters = useCallback((data) => {
    return data.filter(order => {
      const orderDate = new Date(order.order_date).setHours(0, 0, 0, 0);
      const fromDate = filters.fromDate ? new Date(filters.fromDate).setHours(0, 0, 0, 0) : null;
      const toDate = filters.toDate ? new Date(filters.toDate).setHours(23, 59, 59, 999) : null;
  
      return (
        (filters.Distribution === 'All' || order.Distribution === filters.Distribution) &&
        (filters.platform === 'All' || order.platform === filters.platform) &&
        (filters.status === 'All' || order.status === filters.status) &&
        (!fromDate || orderDate >= fromDate) &&
        (!toDate || orderDate <= toDate)
      );
    });
  }, [filters]);

  const initializeCharts = useCallback((data) => {
    const platformData = calculatePlatformData(data);
    const statusData = calculateStatusData(data);
    const salesData = calculateSalesData(data);

    const commonResponsiveOptions = [{
      breakpoint: 480,
      options: {
        chart: {
          height: 300
        },
        legend: {
          position: 'bottom',
          offsetY: 0,
          height: 50
        },
        plotOptions: {
          pie: {
            donut: {
              size: '65%'
            }
          }
        }
      }
    }];

    const platformOptions = {
      series: platformData.series,
      chart: {
        type: 'donut',
        height: 350
      },
      labels: platformData.labels,
      colors: ['#3498db', '#e67e22', '#9b59b6', '#1abc9c', '#34495e'],
      title: {
        text: 'Orders by Platform',
        align: 'center'
      },
      legend: {
        position: 'bottom'
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                }
              }
            }
          }
        }
      },
      responsive: commonResponsiveOptions
    };

    const statusOptions = {
      series: statusData.series,
      chart: {
        type: 'donut',
        height: 350
      },
      labels: statusData.labels,
      colors: ['#2ecc71', '#f39c12'],
      title: {
        text: 'Order Status Distribution',
        align: 'center'
      },
      legend: {
        position: 'bottom'
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                }
              }
            }
          }
        }
      },
      responsive: commonResponsiveOptions
    };

    const salesOptions = {
      series: salesData.series,
      chart: {
        height: 350,
        type: 'line'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: salesData.categories
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy'
        },
      },
      title: {
        text: 'Orders by Date and Platform',
        align: 'center'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 300
          },
          legend: {
            position: 'bottom'
          },
          xaxis: {
            labels: {
              rotate: -45,
              maxHeight: 60
            }
          }
        }
      }]
    };

    setChartOptions({
      platformOptions,
      statusOptions,
      salesOptions
    });
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchDashboardData(filters);
      const data = response[0];
      const filteredData = applyFilters(data);
      setDashboardData(filteredData);
      initializeCharts(filteredData);
      calculateSummaryData(filteredData);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  }, [filters, applyFilters, initializeCharts]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const calculatePlatformData = (data) => {
    const platformCounts = data.reduce((acc, order) => {
      acc[order.platform] = (acc[order.platform] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: Object.keys(platformCounts),
      series: Object.values(platformCounts)
    };
  };

  const calculateStatusData = (data) => {
    const statusCounts = data.reduce((acc, order) => {
      acc[order.status] = (acc[order.status] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: Object.keys(statusCounts),
      series: Object.values(statusCounts)
    };
  };

  const calculateSalesData = (data) => {
    const salesByPlatform = data.reduce((acc, order) => {
      if (!acc[order.platform]) {
        acc[order.platform] = {};
      }
      const date = new Date(order.order_date).toISOString().split('T')[0];
      acc[order.platform][date] = (acc[order.platform][date] || 0) + order.total_sales_value;
      return acc;
    }, {});

    const categories = [...new Set(data.map(order => new Date(order.order_date).toISOString().split('T')[0]))].sort();

    const series = Object.keys(salesByPlatform).map(platform => ({
      name: platform,
      data: categories.map(date => salesByPlatform[platform][date] || 0)
    }));

    return { categories, series };
  };

  const calculateTotalOrders = () => dashboardData.length;
  const calculateTotalWeightage = () => dashboardData.reduce((sum, order) => sum + order.volume, 0).toFixed(2);
  const calculateApprovedOrders = () => dashboardData.filter(order => order.status === 'Approved').length;
  const calculatePendingOrders = () => dashboardData.filter(order => order.status === 'Pending').length;

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const calculateSummaryData = (data) => {
    const summary = data.reduce((acc, order) => {
      const platform = order.platform;
      if (!acc[platform]) {
        acc[platform] = {
          platform,
          totalOrders: 0,
          totalValue: 0,
          totalQuantity: 0,
          totalVolume: 0,
          approvedOrders: 0,
          pendingOrders: 0
        };
      }
      acc[platform].totalOrders += 1;
      acc[platform].totalValue += order.total_sales_value;
      acc[platform].totalQuantity += order.quantity;
      acc[platform].totalVolume += order.volume;
      if (order.status === 'Approved') {
        acc[platform].approvedOrders += 1;
      } else if (order.status === 'Pending') {
        acc[platform].pendingOrders += 1;
      }
      return acc;
    }, {});

    setSummaryData(Object.values(summary));
  };

  return (
    <div className="dashboard-content">
      <div className="summary-scroll-container">
        <div className="scrollable-wrapper">
          <div className="filters" role="search" aria-label="Filter orders">
            
            <div className="filter">
              <label htmlFor="distribution">Distribution</label>
              <select 
                id="distribution"
                value={filters.Distribution}
                onChange={(e) => handleFilterChange('Distribution', e.target.value)}
              >
                <option value="All">All</option>
                <option value="E-Commerce">E-Commerce</option>
                <option value="Modern Trade">Modern Trade</option>
              </select>
            </div>
            <div className="filter">
              <label>Platform</label>
              <select onChange={(e) => handleFilterChange('platform', e.target.value)}>
                <option value="All">All</option>
                <option value="Flipkart">Flipkart</option>
                <option value="Amazon">Amazon</option>
                <option value="Blinkit">Blinkit</option>
                <option value="Big Basket">Big Basket</option>
                <option value="Zepto">Zepto</option>
              </select>
            </div>
            <div className="filter">
              <label>From Date</label>
              <input type="date" onChange={(e) => handleFilterChange('fromDate', e.target.value)} />
            </div>
            <div className="filter">
              <label>To Date</label>
              <input type="date" onChange={(e) => handleFilterChange('toDate', e.target.value)} />
            </div>
            <div className="filter">
              <label>Status</label>
              <select onChange={(e) => handleFilterChange('status', e.target.value)}>
                <option value="All">All</option>
                <option value="Approved">Approved</option>
                <option value="Pending">Pending</option>
              </select>
            </div>
          </div>

          <div className="metrics" role="region" aria-label="Key metrics">
            <div className="metric-card">
              <div className="metric-icon" style={{ color: 'var(--accent-color)' }}>
                <i className="fas fa-shopping-cart"></i>
              </div>
              <div className="metric-content">
                <h3>Total Orders</h3>
                <p>{calculateTotalOrders()}</p>
              </div>
            </div>
            <div className="metric-card">
              <div className="metric-icon" style={{ color: 'var(--success-color)' }}>
                <i className="fas fa-weight-hanging"></i>
              </div>
              <div className="metric-content">
                <h3>Total Weightage</h3>
                <p>{calculateTotalWeightage()} kg</p>
              </div>
            </div>
            <div className="metric-card">
              <div className="metric-icon" style={{ color: 'var(--warning-color)' }}>
                <i className="fas fa-check-circle"></i>
              </div>
              <div className="metric-content">
                <h3>Approved Orders</h3>
                <p>{calculateApprovedOrders()}</p>
              </div>
            </div>
            <div className="metric-card">
              <div className="metric-icon" style={{ color: 'var(--danger-color)' }}>
                <i className="fas fa-clock"></i>
              </div>
              <div className="metric-content">
                <h3>Pending Approval</h3>
                <p>{calculatePendingOrders()}</p>
              </div>
            </div>
          </div>

          <div className="chart-container" role="region" aria-label="Order analytics">
            <div className="chart-area chart-responsive">
              {chartOptions.platformOptions && (
                <ReactApexChart 
                  options={chartOptions.platformOptions} 
                  series={chartOptions.platformOptions.series} 
                  type="donut"
                  height={window.innerWidth <= 768 ? 300 : 350}
                />
              )}
            </div>
            <div className="chart-area chart-responsive">
              {chartOptions.statusOptions && (
                <ReactApexChart 
                  options={chartOptions.statusOptions} 
                  series={chartOptions.statusOptions.series} 
                  type="donut"
                  height={window.innerWidth <= 768 ? 300 : 350}
                />
              )}
            </div>
          </div>

          <div className="table-area" role="region" aria-label="Summary table">
            <h2>Summary Table</h2>
            <div className="table-scroll">
              <table>
                <thead>
                  <tr>
                    <th>Platform</th>
                    <th>Total Orders</th>
                    <th>Total Value</th>
                    <th>Total Quantity</th>
                    <th>Total Volume</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {summaryData.map((platform) => (
                    <tr key={platform.platform}>
                      <td>{platform.platform}</td>
                      <td>{platform.totalOrders}</td>
                      <td>₹{platform.totalValue.toFixed(2)}</td>
                      <td>{platform.totalQuantity}</td>
                      <td>{platform.totalVolume.toFixed(2)} kg</td>
                      <td>
                        <div className="status-container">
                          <span className="status-pill status-approved">{platform.approvedOrders} Approved</span>
                          <span className="status-pill status-pending">{platform.pendingOrders} Pending</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="chart-area chart-responsive">
            {chartOptions.salesOptions && (
              <ReactApexChart 
                options={chartOptions.salesOptions} 
                series={chartOptions.salesOptions.series} 
                type="line"
                height={window.innerWidth <= 768 ? 300 : 350}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SummaryPage;